import React from 'react';
import Layout from '../components/layout/Layout';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Helmet} from 'react-helmet';
import Modal from 'react-modal';

const Index = ({data}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [imageSource, setImageSource] = React.useState('');

    const openModal = (imageSource) => {
        setImageSource(imageSource);
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }
    return (
  <Layout>
    <Helmet>
      <title>{data.strapiCompanyData.name} | {data.strapiHomepage.seo.title}</title>
    </Helmet>
    <h2 className="px-3 md:px-0 text-center text-3xl sm:text-4xl">{data.strapiHomepage.seo.description}</h2>
    <div className="px-3 md:px-0 text-center text-3xl sm:text-4xl mb-10">Tel: {data.strapiCompanyData.phone}</div>
    <div className="px-3 md:px-0 grid-cols-1 grid lg:grid-cols-2 lg:gap-6">
        <button onClick={() => openModal(data.strapiHomepage.imageLeft.localFile.childImageSharp.original.src)}>
          <Img className="mb-5 lg:mb-0 rounded" fluid={data.strapiHomepage.imageLeft.localFile.childImageSharp.fluid} />
        </button>
        <button onClick={() => openModal(data.strapiHomepage.imageRight.localFile.childImageSharp.original.src)}>
          <Img className="rounded" fluid={data.strapiHomepage.imageRight.localFile.childImageSharp.fluid} />
        </button>
    </div>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Bild des Ladens"
          className="image-modal"
      >
        <button className="button" onClick={closeModal}>X Schließen</button>
        <img className="rounded block mx-auto" alt="Bild des Ladens" src={imageSource} />
      </Modal>
  </Layout>
)
};

export const query = graphql`
query HomePage {
  strapiHomepage {
    seo {
      title
      description
    }
    imageLeft {
      localFile {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
          original {
            src
          }
        }
      }
    }
    imageRight {
      localFile {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
          original {
            src
          }
        }
      }
    }
  }
  strapiCompanyData {
    name
    phone
    email
  }
}
`

export default Index;
